import { selectApplicantResumeEducationLevel } from 'src/models/applicant/resume/blocks/educationLevel/selectors';
import { selectApplicantResumePrimaryEducation } from 'src/models/applicant/resume/blocks/primaryEducation/selectors';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate';
import { isChangedByKeys, isNewArrayField, updateItemById } from 'src/models/applicant/resume/editor/lib';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig, PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { PrimaryEducation } from 'src/models/resume/resumeCommon.types';

import {
    getSelectedPrimaryEducationByIndex,
    isNewPrimaryEducationChanged,
} from 'src/models/applicant/resume/blocks/primaryEducation/editor/lib';
import { selectResumeEditorPrimaryEducation } from 'src/models/applicant/resume/blocks/primaryEducation/editor/selectors';

export const PRIMARY_EDUCATION_FIELD = 'primaryEducation';
type PrimaryEducationFields = typeof PRIMARY_EDUCATION_FIELD;

export type PrimaryEducationBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, PrimaryEducationFields>>;

const isPrimaryEducationChanges = isChangedByKeys<PrimaryEducation>([
    'id',
    'name',
    'organization',
    'result',
    'year',
    'universityId',
    'specialityId',
    'facultyId',
]);

export const primaryEducationConfig: PrimaryEducationBlockConfig = {
    init: (store) => {
        return {
            primaryEducation: getSelectedPrimaryEducationByIndex(store),
        };
    },

    changed: (store) => {
        const editorPrimaryEducation = selectResumeEditorPrimaryEducation(store);
        const resumePrimaryEducations = selectApplicantResumePrimaryEducation(store);

        if (!editorPrimaryEducation) {
            return false;
        }

        if (isNewArrayField(editorPrimaryEducation)) {
            return isNewPrimaryEducationChanged(editorPrimaryEducation);
        }

        const resumePrimaryEducation = resumePrimaryEducations.find(({ id }) => id === editorPrimaryEducation.id);
        if (!resumePrimaryEducation) {
            return false;
        }

        return isPrimaryEducationChanges(resumePrimaryEducation, editorPrimaryEducation);
    },

    prepare: ({ primaryEducation }, store) => {
        const resumeEducationLevel = selectApplicantResumeEducationLevel(store);
        const resumePrimaryEducation = selectApplicantResumePrimaryEducation(store);
        const educationLevel = [{ string: resumeEducationLevel === '' ? 'higher' : resumeEducationLevel }];

        if (isNewArrayField(primaryEducation)) {
            return {
                educationLevel,
                primaryEducation: [
                    ...resumePrimaryEducation,
                    { ...primaryEducation, id: null } as unknown as PrimaryEducation,
                ],
            };
        }

        return {
            educationLevel,
            primaryEducation: updateItemById(resumePrimaryEducation, primaryEducation),
        };
    },

    validate: ({ primaryEducation }, conditions) => ({
        ...validateEditorFieldByKey('primaryEducation', primaryEducation, conditions),
    }),

    delete: (store, payload) => {
        const primaryEducation = selectApplicantResumePrimaryEducation(store);
        const { index } = payload as PayloadType<{ index: number }>;
        return { primaryEducation: primaryEducation.filter((_, i) => i !== index) };
    },
};
