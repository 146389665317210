import { DefaultRootState } from 'react-redux';

import { selectApplicantResumeCertificates } from 'src/models/applicant/resume/blocks/certificates/selectors';
import { NEW_ID } from 'src/models/applicant/resume/editor/lib';
import { selectResumeEditorPayload } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Certificate } from 'src/models/resume/resumeCommon.types';

export const getNewCertificate = (): Certificate => ({
    id: NEW_ID,
    achievementDate: '',
    ownerName: null,
    password: null,
    type: 'custom',
    selected: true,
    title: '',
    url: '',
    transcriptionId: null,
});

export const getSelectedCertificateByIndex = (store: DefaultRootState): Certificate => {
    const { index } = selectResumeEditorPayload(store) as PayloadType<{ index: number }>;
    const cetificates = selectApplicantResumeCertificates(store);

    return cetificates[index] || getNewCertificate();
};

export const isNewCertificateChanged = ({ title, achievementDate, url }: Certificate): boolean =>
    title !== '' || achievementDate !== '' || url !== '';
