import { fetcher } from 'src/utils/fetcher';

declare global {
    interface FetcherPostApi {
        '/shards/validate_url': {
            body: { value: string };
            response: { value: string };
            queryParams: void;
        };
    }
}

export const ValidateUrlApi = {
    validate(url: string): Promise<{ value: string }> {
        return fetcher.post('/shards/validate_url', { value: url }).then(({ data }) => data);
    },
};
