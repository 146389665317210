import { DefaultRootState } from 'react-redux';

import { PreferredContact, PersonalSite, Phone, PhoneType } from 'src/models/applicant/resume/blocks/contacts/types';
import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';

export const selectResumeEditorOpenedContacts = selectResumeEditorOpenedType('contacts');

export const selectResumeEditorEmail = ({ resumeEditor }: DefaultRootState): string => resumeEditor.fields.email || '';

const DEFAULT_PHONE_ARRAY: Phone[] = [];
export const selectResumeEditorPhone = ({ resumeEditor }: DefaultRootState): Phone[] =>
    resumeEditor.fields.phone || DEFAULT_PHONE_ARRAY;

const DEFAULT_PHONE = { type: PhoneType.Cell, formatted: '' } as const;
export const selectResumeEditorPhoneByindex =
    (index: number) =>
    ({ resumeEditor }: DefaultRootState): Phone =>
        resumeEditor.fields.phone?.[index] || DEFAULT_PHONE;

export const selectResumeEditorPreferredContact = ({ resumeEditor }: DefaultRootState): PreferredContact =>
    resumeEditor.fields.preferredContact ?? PreferredContact.CellPhone;
export const selectResumeEditorPersonalSite = ({ resumeEditor }: DefaultRootState): PersonalSite[] =>
    resumeEditor.fields.personalSite ?? [];
