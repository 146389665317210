import { fetchProfessionalRoleTree } from 'src/hooks/useFetchProfessionalRoleTree';
import { Lang } from 'src/models/langs';
import { fetcher } from 'src/utils/fetcher';

import { RolesCatalogDTO, PopularAreasDTO, IndustriesTreeDTO } from 'src/models/applicant/resume/api/dto';

const ROLES_CATALOG_URL = '/shards/roles_catalog';
const POPULAR_AREAS_URL = '/shards/popular_areas';
const INDUSTRY_URL = '/shards/industry';
export const FACULTIES_URL = '/shards/applicant/faculties_by_university_id';

export interface FacultyDto {
    id: number;
    text: string;
}

export interface FacultiesDTO {
    faculties: FacultyDto[];
}

declare global {
    interface FetcherGetApi {
        [ROLES_CATALOG_URL]: {
            queryParams: {
                lang: Lang;
            };
            response: RolesCatalogDTO;
        };
        [POPULAR_AREAS_URL]: {
            queryParams: {
                lang: Lang;
            };
            response: PopularAreasDTO;
        };
        [INDUSTRY_URL]: {
            queryParams: {
                lang: string;
                site: string;
                useParentId?: boolean;
            };
            response: IndustriesTreeDTO;
        };
        [FACULTIES_URL]: {
            queryParams: {
                universityId: string;
            };
            response: FacultiesDTO;
        };
    }
}

export const fetchRolesCatalog = (lang: Lang): Promise<RolesCatalogDTO> =>
    fetcher.get(ROLES_CATALOG_URL, {
        params: { lang },
    });

export const fetchPopularAreas = (lang: Lang): Promise<PopularAreasDTO> =>
    fetcher.get(POPULAR_AREAS_URL, {
        params: { lang },
    });

export const fetchIndustriesTree = (lang: Lang, site: string): Promise<IndustriesTreeDTO> =>
    fetcher.get(INDUSTRY_URL, {
        params: {
            lang,
            site,
        },
    });

export const fetchProfessionalRole = fetchProfessionalRoleTree;

export const getFacultiesByUniversityId = (universityId: string): Promise<FacultyDto[]> => {
    return fetcher
        .get(`/shards/applicant/faculties_by_university_id`, { params: { universityId } })
        .then((data) => data.faculties);
};
