import { DefaultRootState } from 'react-redux';

import { selectApplicantResumePrimaryEducation } from 'src/models/applicant/resume/blocks/primaryEducation/selectors';
import { NEW_ID } from 'src/models/applicant/resume/editor/lib';
import { selectResumeEditorPayload } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { PrimaryEducation } from 'src/models/resume/resumeCommon.types';

export const getNewPrimaryEducation = (): PrimaryEducation => ({
    educationLevel: null,
    id: NEW_ID,
    name: '',
    organization: '',
    result: '',
    year: 0,
    universityId: null,
    specialityId: null,
    facultyId: null,
});

export const isNewPrimaryEducationChanged = ({ name, organization, year, result }: PrimaryEducation): boolean => {
    return name !== '' || organization !== '' || year !== 0 || result !== '';
};

export const getSelectedPrimaryEducationByIndex = (store: DefaultRootState): PrimaryEducation => {
    const { index } = selectResumeEditorPayload(store) as PayloadType<{ index: number }>;
    const primaryEducation = selectApplicantResumePrimaryEducation(store);

    const education = primaryEducation[index];
    if (education) {
        return {
            educationLevel: education.educationLevel,
            id: education.id,
            name: education.name === null ? '' : education.name,
            organization: education.organization === null ? '' : education.organization,
            result: education.result === null ? '' : education.result,
            year: education.year === null ? 0 : education.year,
            universityId: education.universityId,
            specialityId: education.specialityId,
            facultyId: education.facultyId,
        };
    }
    return primaryEducation[index] || getNewPrimaryEducation();
};
