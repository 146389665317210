import { fetchPopularAreas, fetchIndustriesTree } from 'src/models/applicant/resume/api/dictionaries';
import { selectResumeExperience } from 'src/models/applicant/resume/blocks/experience/selectors';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate/index';
import { isNewArrayField, isChangedByKeys, updateItemById } from 'src/models/applicant/resume/editor/lib';
import { setResumeEditorDictionaries } from 'src/models/applicant/resume/editor/store/slice';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig, PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Experience } from 'src/models/resume/resumeCommon.types';

import {
    getSelectedExperienceByIndex,
    isNewExperienceChanged,
    transformExperienceToSave,
} from 'src/models/applicant/resume/blocks/experience/editor/lib';
import {
    selectResumeEditorExperience,
    selectPopularAreas,
    selectIndustriesTree,
} from 'src/models/applicant/resume/blocks/experience/editor/selectors';

export const EXPERIENCE_FIELD = 'experience';
export type ExperienceBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, typeof EXPERIENCE_FIELD>>;

const isExperienceChanges = isChangedByKeys<Partial<Experience>>([
    'position',
    'description',
    'startDate',
    'endDate',
    'companyUrl',
    'companyAreaId',
    'companyName',
    'companyIndustries',
]);

export const experienceConfig: ExperienceBlockConfig = {
    preload: async (store, dispatch) => {
        const lang = store.langs?.[0] || 'RU';
        let popularAreas = selectPopularAreas(store);
        let industriesTree = selectIndustriesTree(store);

        if (!popularAreas) {
            popularAreas = await fetchPopularAreas(lang);
        }

        if (!industriesTree) {
            industriesTree = await fetchIndustriesTree(lang, window.globalVars.siteId);
        }
        dispatch(
            setResumeEditorDictionaries({
                popularAreas,
                industriesTree,
            })
        );
    },
    init: (store) => {
        return {
            experience: getSelectedExperienceByIndex(store),
        };
    },

    changed: (store) => {
        const editorExperience = selectResumeEditorExperience(store);
        const resumeExperiences = selectResumeExperience(store);

        if (!editorExperience) {
            return false;
        }

        if (isNewArrayField(editorExperience)) {
            return isNewExperienceChanged(editorExperience);
        }

        const resumeExperience = resumeExperiences.find(({ id }) => id === editorExperience.id);

        if (!resumeExperience) {
            return false;
        }

        return isExperienceChanges(resumeExperience, editorExperience);
    },

    prepare: ({ experience }, store) => {
        const resumeExperience = selectResumeExperience(store);
        let experienceToSave: Experience[] = [];

        if (isNewArrayField(experience)) {
            experienceToSave = [...resumeExperience, { ...experience, id: null } as unknown as Experience];
        } else {
            experienceToSave = updateItemById(resumeExperience, experience);
        }

        return {
            experience: transformExperienceToSave(experienceToSave),
        };
    },

    validate: ({ experience }, conditions) => ({
        ...validateEditorFieldByKey('experience', experience, conditions),
    }),

    delete: (store, payload) => {
        const experience = selectResumeExperience(store);

        const { index } = payload as PayloadType<{ index: number }>;

        return { experience: transformExperienceToSave(experience.filter((_, i) => i !== index)) };
    },
};
