import { DefaultRootState } from 'react-redux';

import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';

export const selectResumeEditorOpenedPortfolio = selectResumeEditorOpenedType('portfolio');

const DEFAULT_PORTFOLIO: number[] = [];

export const selectResumeEditorPortfolio = ({ resumeEditor }: DefaultRootState): number[] =>
    resumeEditor.fields.portfolio || DEFAULT_PORTFOLIO;
