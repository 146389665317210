import { selectApplicantResumeCertificates } from 'src/models/applicant/resume/blocks/certificates/selectors';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate/index';
import { isChangedByKeys, isNewArrayField, updateItemById } from 'src/models/applicant/resume/editor/lib';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig, PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Certificate } from 'src/models/resume/resumeCommon.types';
import { ValidateUrlApi } from 'src/models/validateUrl/api';

import {
    getSelectedCertificateByIndex,
    isNewCertificateChanged,
} from 'src/models/applicant/resume/blocks/certificates/editor/lib';
import { selectResumeEditorCertificate } from 'src/models/applicant/resume/blocks/certificates/editor/selectors';

export const CERTIFICATE_FIELDS = ['certificate'] as const;
type CertificateFields = (typeof CERTIFICATE_FIELDS)[number];

export type CertificateBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, CertificateFields>>;

const isCertificatesChanges = isChangedByKeys<Certificate>(['achievementDate', 'title', 'url']);

export const certificateConfig: CertificateBlockConfig = {
    init: (store) => {
        return {
            certificate: getSelectedCertificateByIndex(store),
        };
    },

    changed: (store) => {
        const editorCertificate = selectResumeEditorCertificate(store);
        const resumeCertificates = selectApplicantResumeCertificates(store);

        if (!editorCertificate) {
            return false;
        }

        if (isNewArrayField(editorCertificate)) {
            return isNewCertificateChanged(editorCertificate);
        }

        const resumeCertificate = resumeCertificates.find(({ id }) => id === editorCertificate.id);
        if (!resumeCertificate) {
            return false;
        }
        return isCertificatesChanges(resumeCertificate, editorCertificate);
    },

    prepare: ({ certificate }, store) => {
        const resumeCertificate = selectApplicantResumeCertificates(store);

        if (isNewArrayField(certificate)) {
            return {
                certificate: [...resumeCertificate, { ...certificate, id: null } as unknown as Certificate],
            };
        }

        return {
            certificate: updateItemById(resumeCertificate, certificate),
        };
    },

    validate: ({ certificate }, conditions) => {
        return {
            ...validateEditorFieldByKey('certificate', certificate, conditions),
        };
    },
    asyncValidate: async ({ certificate }, fieldIndex) => {
        let certIndex = fieldIndex;
        if (certIndex === undefined) {
            const lastIndex = certificate.length - 1;
            if (lastIndex >= 0) {
                if (isNewArrayField(certificate[lastIndex])) {
                    certIndex = lastIndex;
                }
            }
        }
        if (certIndex !== undefined) {
            const currentCertificate = certificate[certIndex];
            if (!currentCertificate.url) {
                return { fields: {}, errors: {} };
            }
            try {
                const result = await ValidateUrlApi.validate(currentCertificate.url);
                if (result.value) {
                    return {
                        fields: {
                            certificate: certificate.map((item, index) => ({
                                ...item,
                                url: index === certIndex ? result.value : item.url,
                            })),
                        },
                        errors: {},
                    };
                }
            } catch {
                return {
                    fields: {},
                    errors: {
                        certificate: [
                            {
                                field: 'certificate',
                                fieldNamePath: `/certificate/${fieldIndex}/url`,
                                type: 'BUSINESS',
                                code: 'INVALID_URL',
                                value: currentCertificate.url,
                                message: '',
                            },
                        ],
                    },
                };
            }
        }

        return { fields: {}, errors: {} };
    },

    delete: (store, payload) => {
        const certificates = selectApplicantResumeCertificates(store);
        const { index } = payload as PayloadType<{ index: number }>;
        return { certificate: certificates.filter((_, i) => i !== index) };
    },
};
