import { DefaultRootState } from 'react-redux';

import { selectResumeStringField } from 'src/models/applicant/resume/selectors';
import { StringFieldType } from 'src/models/applicant/resume/types/common';
import { PhotoUrl } from 'src/models/resume/resumeCommon.types';

import { WITHOUT_PHOTO_ID } from 'src/models/applicant/resume/blocks/position/edit/photo/constants';
import { Gender, Salary } from 'src/models/applicant/resume/blocks/position/types';

export const selectApplicantResumePhotoUrl = ({ applicantResume }: DefaultRootState): string | undefined => {
    const photoId = applicantResume.photo?.[0]?.string;
    if (photoId) {
        const photoUrls = applicantResume?.photoUrls || [];
        const photoUrl = photoUrls.find(({ id }) => `${id}` === `${photoId}`);
        if (photoUrl) {
            return photoUrl.big;
        }
    }
    return undefined;
};
export const selectApplicantPhotoUrls = ({ applicantResume }: DefaultRootState): PhotoUrl[] =>
    applicantResume.photoUrls;

export const selectApplicantResumePhoto = selectResumeStringField<number>('photo', WITHOUT_PHOTO_ID);
export const selectApplicantResumeTitle = selectResumeStringField('title', '');
export const selectApplicantResumeGender = selectResumeStringField('gender', Gender.Unknown);
export const selectApplicantResumeLastName = selectResumeStringField('lastName', '');
export const selectApplicantResumeFirstName = selectResumeStringField('firstName', '');

export const selectApplicantResumeProfessionalRole = ({ applicantResume }: DefaultRootState): StringFieldType[] =>
    applicantResume.professionalRole;

const DEFAULT_SALARY = { amount: null, currency: 'RUR' };

export const selectApplicantResumeSalary = ({ applicantResume }: DefaultRootState): Salary =>
    applicantResume?.salary?.[0] ?? DEFAULT_SALARY;
