import { VerifiedSkillLevel } from 'src/models/resume/resumeVerifiedSkills.types';
import { SkillsLevelsKeys } from 'src/models/skills';

export const SKILLS_LEVELS_ORDER: SkillsLevelsKeys[] = [
    SkillsLevelsKeys.ADVANCED,
    SkillsLevelsKeys.MEDIUM,
    SkillsLevelsKeys.BASIC,
    SkillsLevelsKeys.NONE,
];

export const SKILLS_LEVELS_BY_RANK: Record<VerifiedSkillLevel['rank'], SkillsLevelsKeys> = {
    0: SkillsLevelsKeys.NONE,
    1: SkillsLevelsKeys.BASIC,
    2: SkillsLevelsKeys.MEDIUM,
    3: SkillsLevelsKeys.ADVANCED,
};
