import { DefaultRootState } from 'react-redux';

import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { PrimaryEducation } from 'src/models/resume/resumeCommon.types';

export const selectResumeEditorOpenedPrimaryEducation = selectResumeEditorOpenedType('primaryEducation');

export const selectResumeEditorPrimaryEducation = ({ resumeEditor }: DefaultRootState): PrimaryEducation | undefined =>
    resumeEditor.fields.primaryEducation;

export const selectResumeEditorPrimaryEducationIndex = ({
    resumeEditor,
    applicantResume,
}: DefaultRootState): number => {
    const { index } = resumeEditor.payload as PayloadType<{ index: number }>;
    if (index === undefined) {
        return applicantResume.primaryEducation.length;
    }
    return index;
};
