import { sortPhoneByType } from 'src/models/applicant/resume/blocks/contacts/lib';
import {
    selectResumeEmail,
    selectResumePersonalSite,
    selectResumePhone,
    selectResumePreferredContact,
} from 'src/models/applicant/resume/blocks/contacts/selectors';
import { PreferredContact } from 'src/models/applicant/resume/blocks/contacts/types';
import { isArrayChangedByFn } from 'src/models/applicant/resume/editor/form/change/lib';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate/index';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

import { filterEmptyPhone } from 'src/models/applicant/resume/blocks/contacts/editor/lib';
import {
    selectResumeEditorEmail,
    selectResumeEditorPhone,
    selectResumeEditorPersonalSite,
    selectResumeEditorPreferredContact,
} from 'src/models/applicant/resume/blocks/contacts/editor/selectors';
import { validatePersonalSite } from 'src/models/applicant/resume/blocks/contacts/editor/validate';

export const CONTATCS_FIELDS = ['phone', 'email', 'preferredContact', 'personalSite'] as const;
type ContactsFields = (typeof CONTATCS_FIELDS)[number];

export type ContactsBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, ContactsFields>>;

export const contactsConfig: ContactsBlockConfig = {
    init: (store) => {
        return {
            phone: sortPhoneByType(selectResumePhone(store)),
            email: selectResumeEmail(store),
            personalSite: selectResumePersonalSite(store),
            preferredContact: selectResumePreferredContact(store),
        };
    },
    changed: (store) => {
        const editorEmail = selectResumeEditorEmail(store);
        const editorPhone = selectResumeEditorPhone(store);
        const editorPersonalSite = selectResumeEditorPersonalSite(store);
        const editorPreferredContact = selectResumeEditorPreferredContact(store);

        const resumeEmail = selectResumeEmail(store);
        const resumePhone = selectResumePhone(store);
        const resumePersonalSite = selectResumePersonalSite(store);
        const resumePreferredContact = selectResumePreferredContact(store);

        return (
            editorEmail !== resumeEmail ||
            editorPreferredContact !== resumePreferredContact ||
            isArrayChangedByFn(
                editorPhone,
                resumePhone,
                (a, b) => a.formatted === b.formatted && a.comment === b.comment && b.type === a.type
            ) ||
            isArrayChangedByFn(editorPersonalSite, resumePersonalSite, (a, b) => a.url === b.url)
        );
    },
    prepare: ({ phone, email, preferredContact, personalSite }) => {
        return {
            email: email ? [{ string: String(email) }] : [],
            phone: filterEmptyPhone(phone),
            personalSite: personalSite.filter(({ url }) => !!url),
            preferredContact: [{ string: String(preferredContact) as PreferredContact }],
        };
    },
    validate: ({ email, personalSite }, conditions) => {
        return {
            ...validatePersonalSite(personalSite, conditions),
            ...validateEditorFieldByKey('email', email, conditions),
        };
    },
};
