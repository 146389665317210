import { ArtifactsApi } from 'src/models/applicant/resume/api/artifacts';
import { setApplicantResumeArtifacts } from 'src/models/applicant/resume/artifacts/slice';
import { selectApplicantPortfolio } from 'src/models/applicant/resume/blocks/portfolio/selectors';
import { isArrayChangedByFn } from 'src/models/applicant/resume/editor/form/change/lib';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

import { selectResumeEditorPortfolio } from 'src/models/applicant/resume/blocks/portfolio/edit/selector';

export const PORTFOLIO_FIELD = 'portfolio';
type PortfolioFields = typeof PORTFOLIO_FIELD;

type PortfolioBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, PortfolioFields>>;

export const portfolioConfig: PortfolioBlockConfig = {
    preload: async (_, dispatch) => {
        const artifacts = await ArtifactsApi.getArtifacts('PORTFOLIO');

        dispatch(setApplicantResumeArtifacts(artifacts));
    },

    init: (store) => {
        const applicantPortfolio = selectApplicantPortfolio(store);

        return {
            portfolio: applicantPortfolio.map(({ string }) => string),
        };
    },

    changed: (store) => {
        const editorPortfolio = selectApplicantPortfolio(store);
        const resumePortfolio = selectResumeEditorPortfolio(store);

        return isArrayChangedByFn(editorPortfolio, resumePortfolio, (a, b) => a.string === b);
    },

    prepare: ({ portfolio }) => ({
        portfolio: portfolio.map((string) => ({ string })),
    }),

    validate: ({ portfolio }, conditions) => ({
        ...validateEditorFieldByKey('portfolio', portfolio, conditions),
    }),
};
