import { Phone } from 'src/models/applicant/resume/blocks/contacts/types';

export const filterEmptyPhone = (phone: Phone[]): Phone[] =>
    phone
        .filter((p) => {
            if (p.type === 'cell') {
                return true;
            }
            return !!p.formatted;
        })
        .map((p) => ({
            type: p.type,
            comment: p.comment ?? '',
            formatted: p.formatted,
        }));
