import { selectResumeEditorTravelTime } from 'src/models/applicant/resume/blocks/additional/edit/selectors';
import { selectResumeTravelTime } from 'src/models/applicant/resume/blocks/additional/selectors';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate/index';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

export const TRAVEL_TIME_FIELD = 'travelTime';
type TravelTimeFields = typeof TRAVEL_TIME_FIELD;

type TravelTimeBlock = EditorConfig<Pick<ApplicantResumeEditorFields, TravelTimeFields>>;

export const travelTimeConfig: TravelTimeBlock = {
    init: (store) => ({
        travelTime: selectResumeTravelTime(store),
    }),
    changed: (store) => {
        const editorTravelTime = selectResumeEditorTravelTime(store);
        const resumeTravelTime = selectResumeTravelTime(store);

        return editorTravelTime !== resumeTravelTime;
    },
    prepare: ({ travelTime }) => ({
        travelTime: [{ string: travelTime }],
    }),
    validate: ({ travelTime }, conditions) => ({
        ...validateEditorFieldByKey('travelTime', travelTime, conditions),
    }),
};
