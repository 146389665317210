import { ConditionPart } from 'src/models/applicant/resume/types/conditions';

export function isZeroTrue(value: number | string): boolean {
    return value === 0 ? true : !!value;
}

export function isStringOrNumberType(value: number | string | boolean): value is string | number {
    return typeof value === 'string' || typeof value === 'number';
}

export function isEmptyValue(value: string | boolean | number, conditions: ConditionPart): boolean {
    if (conditions.type === 'boolean') {
        return value !== true && value !== false;
    }
    if (conditions.type === 'string' && conditions.enum) {
        return !conditions.enum.includes(value as string);
    }

    if (conditions.type === 'integer' && isStringOrNumberType(value)) {
        return !isZeroTrue(value);
    }

    return !value;
}

export function getDayInMonth(year: number, month: number): number {
    return new Date(new Date(year, month, 1).getTime() - 1).getDate();
}

export type ErrorCode =
    | 'UNKNOWN'
    | 'REQUIRED'
    | 'DUPLICATE'
    | 'NOT_IN_ENUM'
    | 'NOT_MATCH_REGEXP'
    | 'EARLY_THAN_MINIMUM'
    | 'LATER_THAN_MAXIMUM'
    | 'SIZE_LESS_THAN_MINIMUM'
    | 'VALUE_LESS_THAN_MINIMUM'
    | 'LENGTH_LESS_THAN_MINIMUM'
    | 'SIZE_GREATER_THAN_MAXIMUM'
    | 'VALUE_GREATER_THAN_MAXIMUM'
    | 'END_DATE_BEFORE_START_DATE'
    | 'LENGTH_GREATER_THAN_MAXIMUM'
    | 'REQUIRED_WITH_NOT_EMPTY_EXPERIENCE';
