import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorFieldError } from 'src/models/applicant/resume/editor/types/error';
import { Condition, ConditionPart } from 'src/models/applicant/resume/types/conditions';

import {
    ErrorCode,
    getDayInMonth,
    isEmptyValue,
    isZeroTrue,
} from 'src/models/applicant/resume/editor/form/validate/lib';

export function validateCount<T extends Record<string, string>>(
    field: keyof ApplicantResumeEditorFields,
    values: T[],
    condition: Condition
): EditorFieldError[] | undefined {
    if (condition.mincount !== null && values.length < condition.mincount) {
        return [
            {
                field,
                code: 'SIZE_LESS_THAN_MINIMUM',
                type: 'CONDITION',
                value: values,
                message: '',
                fieldNamePath: `/${field}`,
            },
        ];
    }

    if (condition.maxcount !== null && values.length > condition.maxcount) {
        return [
            {
                field,
                code: 'SIZE_GREATER_THAN_MAXIMUM',
                type: 'CONDITION',
                value: values,
                message: '',
                fieldNamePath: `/${field}`,
            },
        ];
    }
    return undefined;
}

export const validatePart = (
    partValue: string | number | boolean,
    conditions: ConditionPart
): ErrorCode | undefined => {
    if (!conditions) {
        return undefined;
    }

    if (isEmptyValue(partValue, conditions)) {
        if (conditions.required) {
            return 'REQUIRED';
        }
        return undefined;
    }

    if (conditions.type === 'string') {
        return validateString(String(partValue), conditions);
    }

    if (conditions.type === 'integer') {
        return validateInteger(Number(partValue), conditions);
    }

    if (conditions.type === 'date') {
        return validateDate(String(partValue), conditions);
    }

    return undefined;
};

function validateString(value: string, conditions: ConditionPart): ErrorCode | undefined {
    if (!value) {
        return undefined;
    }
    if (conditions.minlength && value.length < conditions.minlength) {
        return 'LENGTH_LESS_THAN_MINIMUM';
    }

    if (conditions.maxlength && value.length > conditions.maxlength) {
        return 'LENGTH_GREATER_THAN_MAXIMUM';
    }

    if (conditions.regexp && !new RegExp(`^${conditions.regexp}$`).test(value)) {
        return 'NOT_MATCH_REGEXP';
    }

    if (conditions.not_in?.includes?.(value)) {
        return 'DUPLICATE';
    }

    if ('enum' in conditions && !conditions.enum?.includes?.(value)) {
        return 'NOT_IN_ENUM';
    }

    return undefined;
}

function validateInteger(value: number | string, conditions: ConditionPart): ErrorCode | undefined {
    const integer = typeof value === 'string' ? parseInt(value, 10) : value;
    const string = `${value}`;

    if (!isZeroTrue(value)) {
        return undefined;
    }

    if (isNaN(integer)) {
        return 'UNKNOWN';
    }

    if (conditions.minvalue && integer < (conditions.minvalue as number)) {
        return 'VALUE_LESS_THAN_MINIMUM';
    }

    if (conditions.maxvalue && integer > (conditions.maxvalue as number)) {
        return 'VALUE_GREATER_THAN_MAXIMUM';
    }

    if (conditions.maxlength && string.length > conditions.maxlength) {
        return 'LENGTH_GREATER_THAN_MAXIMUM';
    }

    if (conditions.minlength && string.length < conditions.minlength) {
        return 'LENGTH_LESS_THAN_MINIMUM';
    }

    return undefined;
}

function validateDate(value: string, conditions: ConditionPart): ErrorCode | undefined {
    const [year = NaN, month = NaN, day = NaN] = value ? value.split('-').map((value) => parseInt(value, 10)) : [];
    const empty = isNaN(year) && isNaN(month) && isNaN(day);

    if (empty && conditions.required) {
        return 'REQUIRED';
    }

    const date = new Date(year, month - 1, day);
    const maxDayInMonth = getDayInMonth(year, month);

    const minYear = 1000;
    const maxYear = 2999;

    if (year < minYear) {
        return 'EARLY_THAN_MINIMUM';
    }

    if (year > maxYear) {
        return 'LATER_THAN_MAXIMUM';
    }

    const invalid = month < 1 || month > 12 || day < 1 || day > maxDayInMonth;

    if (!empty && (isNaN(date.getTime()) || invalid)) {
        return 'UNKNOWN';
    }

    if (!empty && date.getTime() < new Date(String(conditions.minvalue)).getTime()) {
        return 'EARLY_THAN_MINIMUM';
    }

    if (!empty && date.getTime() > new Date(String(conditions.maxvalue)).getTime()) {
        return 'LATER_THAN_MAXIMUM';
    }

    return undefined;
}
