import { selectApplicantResumeKeySkills } from 'src/models/applicant/resume/blocks/keySkills/selectors';
import { openSkillsLevelsEditor } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/actions';
import { isArrayChangedByFn } from 'src/models/applicant/resume/editor/form/change/lib';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

import { selectResumeEditorKeySkills } from 'src/models/applicant/resume/blocks/keySkills/editor/selector';

export const KEYS_SKILLS_FIELDS = 'keySkills';
type KeySkillsFields = typeof KEYS_SKILLS_FIELDS;

export type KeySkillsConfig = EditorConfig<Pick<ApplicantResumeEditorFields, KeySkillsFields>>;

export const keySkillsConfig: KeySkillsConfig = {
    init: (store) => {
        const keySkills = selectApplicantResumeKeySkills(store);
        return {
            keySkills: keySkills.map(({ string }) => string),
        };
    },

    changed: (store) => {
        const editorKeySkills = selectResumeEditorKeySkills(store);
        const resumeKeySkills = selectApplicantResumeKeySkills(store);

        return isArrayChangedByFn(resumeKeySkills, editorKeySkills, (a, b) => a.string === b);
    },

    prepare: ({ keySkills }) => ({
        keySkills: keySkills.map((string) => ({ string })),
    }),

    validate: ({ keySkills }, conditions) => {
        return {
            ...validateEditorFieldByKey('keySkills', keySkills, conditions),
        };
    },

    afterSubmit: (state, prevState, dispatch) => {
        const resumeKeySkills = selectApplicantResumeKeySkills(state);
        const prevResumeKeySkills = selectApplicantResumeKeySkills(prevState);

        if (isArrayChangedByFn(resumeKeySkills, prevResumeKeySkills, (a, b) => a.string === b.string)) {
            dispatch(openSkillsLevelsEditor());
        }
    },
};
