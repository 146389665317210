import { selectApplicantResumeAdditionalEducation } from 'src/models/applicant/resume/blocks/additionalEducation/selectors';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate';
import { isChangedByKeys, isNewArrayField, updateItemById } from 'src/models/applicant/resume/editor/lib';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig, PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { AdditionalEducation } from 'src/models/resume/resumeCommon.types';

import {
    getSelctedAdditionalEducationByIndex,
    isNewAdditionalEducationChanged,
} from 'src/models/applicant/resume/blocks/additionalEducation/editor/lib';
import { selectResumeEditorAdditionalEducation } from 'src/models/applicant/resume/blocks/additionalEducation/editor/selectors';

export const ADDITIONAL_EDUCATION_FIELD = 'additionalEducation';
type AdditionalEducationFields = typeof ADDITIONAL_EDUCATION_FIELD;

export type AdditionalEducationBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, AdditionalEducationFields>>;

const isAdditionalEducationChanges = isChangedByKeys<AdditionalEducation>(['name', 'organization', 'result', 'year']);

export const additionalEducationConfig: AdditionalEducationBlockConfig = {
    init: (store) => {
        return {
            additionalEducation: getSelctedAdditionalEducationByIndex(store),
        };
    },

    changed: (store) => {
        const editorAdditionalEducation = selectResumeEditorAdditionalEducation(store);
        const resumeAdditionalEducations = selectApplicantResumeAdditionalEducation(store);

        if (!editorAdditionalEducation) {
            return false;
        }

        if (isNewArrayField(editorAdditionalEducation)) {
            return isNewAdditionalEducationChanged(editorAdditionalEducation);
        }

        const resumeAdditionalEducation = resumeAdditionalEducations.find(
            ({ id }) => id === editorAdditionalEducation.id
        );
        if (!resumeAdditionalEducation) {
            return false;
        }

        return isAdditionalEducationChanges(resumeAdditionalEducation, editorAdditionalEducation);
    },

    prepare: ({ additionalEducation }, store) => {
        const resumeAdditionalEducation = selectApplicantResumeAdditionalEducation(store);

        if (isNewArrayField(additionalEducation)) {
            return {
                additionalEducation: [
                    ...resumeAdditionalEducation,
                    { ...additionalEducation, id: null } as unknown as AdditionalEducation,
                ],
            };
        }

        return {
            additionalEducation: updateItemById(resumeAdditionalEducation, additionalEducation),
        };
    },

    validate: ({ additionalEducation }, conditions) => ({
        ...validateEditorFieldByKey('additionalEducation', additionalEducation, conditions),
    }),

    delete: (store, payload) => {
        const additionalEducation = selectApplicantResumeAdditionalEducation(store);
        const { index } = payload as PayloadType<{ index: number }>;
        return { additionalEducation: additionalEducation.filter((_, i) => i !== index) };
    },
};
