import { DefaultRootState } from 'react-redux';

import { VerifiedSkill } from 'src/models/resume/resumeVerifiedSkills.types';

import { LevelBySkillId } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/types';

export const selectResumeEditorKeySkills = ({ resumeEditor }: DefaultRootState): string[] =>
    resumeEditor.fields.keySkills || [];

export const selectSkillsLevelsEditorOpened = ({ applicantSkillsLevelsEditor }: DefaultRootState): boolean =>
    applicantSkillsLevelsEditor.opened || false;

export const selectSkillsLevelsAlertVisibility = ({ applicantSkillsLevelsEditor }: DefaultRootState): boolean =>
    applicantSkillsLevelsEditor.alertVisible || false;

export const selectSkillsLevelsInfoModalVisibility = ({ applicantSkillsLevelsEditor }: DefaultRootState): boolean =>
    applicantSkillsLevelsEditor.infoModalVisible || false;

export const selectSkillLevelList = ({ applicantSkillsLevelsEditor }: DefaultRootState): VerifiedSkill[] =>
    applicantSkillsLevelsEditor.skills || [];

export const selectLevelsBySkillId = ({ applicantSkillsLevelsEditor }: DefaultRootState): LevelBySkillId =>
    applicantSkillsLevelsEditor.levels || {};

export const selectOriginalLevelsBySkillId = ({ applicantSkillsLevelsEditor }: DefaultRootState): LevelBySkillId =>
    applicantSkillsLevelsEditor.originalLevels || {};
