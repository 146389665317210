import { DefaultRootState } from 'react-redux';
import { RouterLocation } from 'connected-react-router';

import { ApplicantUserStatuses } from 'src/models/applicantUserStatuses';
import { LangsList, Statuses } from 'src/models/resume/resumeCommon.types';

import { ApplicantResumeDTO } from 'src/models/applicant/resume/types/resume';

type StringFieldKeys =
    | 'lang'
    | 'photo'
    | 'title'
    | 'email'
    | 'gender'
    | 'skills'
    | 'lastName'
    | 'firstName'
    | 'travelTime'
    | 'accessType'
    | 'preferredContact'
    | 'businessTripReadiness';

export const selectResumeHash = ({ applicantResume }: DefaultRootState): string => applicantResume._attributes.hash;
export const selectResumeStatus = ({ applicantResume }: DefaultRootState): Statuses =>
    applicantResume._attributes.status;
export const selectResumeHasErrors = ({ applicantResume }: DefaultRootState): boolean =>
    applicantResume._attributes.hasErrors;
export const selectResumeLang = ({ applicantResume }: DefaultRootState): LangsList => applicantResume._attributes.lang;
export const selectHhtmSource = ({ analyticsParams }: DefaultRootState): string => analyticsParams.hhtmSource;
export const selectResumeId = ({ applicantResume }: DefaultRootState): string => applicantResume._attributes.id;
export const selectVacancyId = ({ router }: DefaultRootState): string => router.location.query.vacancyId;

export const selectLocation = ({ router }: DefaultRootState): RouterLocation<unknown> => router.location;
export const selectApplicantResume = ({ applicantResume }: DefaultRootState): ApplicantResumeDTO => applicantResume;
export const selectResumeUserId = ({ applicantResume }: DefaultRootState): string => applicantResume._attributes.user;
export const selectResumeUserStatuses = ({ applicantResume }: DefaultRootState): ApplicantUserStatuses =>
    applicantResume.applicantUserStatuses;

export const selectResumeStringField =
    <T>(key: StringFieldKeys, defaultValue: T) =>
    ({ applicantResume }: DefaultRootState): T => {
        return (applicantResume?.[key]?.[0]?.string as T) ?? defaultValue;
    };
