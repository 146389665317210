import { DefaultRootState } from 'react-redux';

import { StringFieldType } from 'src/models/applicant/resume/types/common';
import { Experience } from 'src/models/resume/resumeCommon.types';

export const selectResumeExperience = ({ applicantResume }: DefaultRootState): Experience[] =>
    applicantResume.experience;

export const selectResumeTotalExperience = ({ applicantResume }: DefaultRootState): StringFieldType<number>[] =>
    applicantResume.totalExperience;
