export const SKILL_LEVELS = [
    {
        id: 1,
        name: 'A1 — Начальный',
        rank: 1,
        internal_id: 'a1',
        category: 'LANG',
    },
    {
        id: 2,
        name: 'A2 — Элементарный',
        rank: 2,
        internal_id: 'a2',
        category: 'LANG',
    },
    {
        id: 3,
        name: 'B1 — Средний',
        rank: 3,
        internal_id: 'b1',
        category: 'LANG',
    },
    {
        id: 4,
        name: 'B2 — Средне-продвинутый',
        rank: 4,
        internal_id: 'b2',
        category: 'LANG',
    },
    {
        id: 5,
        name: 'C1 — Продвинутый',
        rank: 5,
        internal_id: 'c1',
        category: 'LANG',
    },
    {
        id: 6,
        name: 'C2 — В совершенстве',
        rank: 6,
        internal_id: 'c2',
        category: 'LANG',
    },
    {
        id: 7,
        name: 'L1 - Родной',
        rank: 7,
        internal_id: 'l1',
        category: 'LANG',
    },
    {
        id: 8,
        name: 'Базовый',
        rank: 1,
        internal_id: 'base',
        category: 'SKILL',
    },
    {
        id: 9,
        name: 'Средний',
        rank: 2,
        internal_id: 'middle',
        category: 'SKILL',
    },
    {
        id: 10,
        name: 'Продвинутый',
        rank: 3,
        internal_id: 'advanced',
        category: 'SKILL',
    },
];
