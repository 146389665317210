import { DefaultRootState } from 'react-redux';

import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';

export const selectResumeEditorOpenedVisibility = selectResumeEditorOpenedType('visibility');

export const selectApplicantResumeEditorAccessType = ({ resumeEditor }: DefaultRootState) =>
    resumeEditor.fields.accessType;

export const selectApplicantResumeEditorHiddenFields = ({ resumeEditor }: DefaultRootState) =>
    resumeEditor.fields.hiddenFields;
