import { VERIFIED_KEY_SKILLS_STATES } from 'src/models/applicant/resume/blocks/keySkills/lib';
import { keys } from 'src/models/applicant/resume/lib/common/object';
import { VerifiedSkill } from 'src/models/resume/resumeVerifiedSkills.types';

import { SKILL_LEVELS } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/constants';
import { LevelBySkillId } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/types';

export function skillSortWeight(skill: VerifiedSkill) {
    const lastVerification = skill.verifications.sort(
        (a, b) => Date.parse(a.attemptedAt) - Date.parse(b.attemptedAt)
    )[0];

    const isVerified = lastVerification && VERIFIED_KEY_SKILLS_STATES.includes(lastVerification.validity.state);
    // Верифицированные навыки в самый конец. Языки в конец, но перед верифицированными
    return 0 + (skill.level ? 1 : 0) + (skill.category === 'LANG' ? 5 : 0) + (isVerified ? 10 : 0);
}

export function filterVerifiedSkill(resumeSkillsNames: string[]) {
    return (verifiedSkill: VerifiedSkill) =>
        verifiedSkill.category === 'SKILL' && resumeSkillsNames.includes(verifiedSkill.name);
}

export function makeLevelsMap(verifiedSkills: VerifiedSkill[]) {
    const levels = verifiedSkills.reduce(
        (acc, skill) => {
            const { id, level } = skill;
            const rank = Math.min(level ? level.rank : 0, 3);
            acc[id] = rank;
            return acc;
        },
        {} as Record<number, number>
    );

    return levels;
}

export function getChangedLevels(originalLevels: LevelBySkillId, levels: LevelBySkillId) {
    return keys(originalLevels).reduce((acc, id) => {
        const originalRank = originalLevels[id];
        const rank = levels[id];
        if (rank !== originalRank) {
            acc[id] = rank;
        }
        return acc;
    }, {} as LevelBySkillId);
}

export const skillLevelViewToLevelId = (rank: number, category: string): number => {
    const level = SKILL_LEVELS.find((x) => x.rank === rank && x.category === category);
    if (level) {
        return level.id;
    }
    return 0;
};
