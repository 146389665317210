import { translation } from '@hh.ru/front-static-app';
import { Card, ProgressBar, Title, VSpacing } from '@hh.ru/magritte-ui';

import { Resume } from 'src/models/resume/resume.types';

import { FieldTags } from 'src/components/ResumeCompleteness/FieldTags';

const TrlKeys = {
    title: 'resume.suitable-vacancies.suggest.title',
};

interface Props {
    max: number;
    fields: Array<keyof Resume>;
    progress: number;
    description: string;
    onClick: (field: keyof Resume) => void;
    redFields?: Array<keyof Resume>;
    redDescription?: string;
}

const ResumeCompleteness = translation<Props>(
    ({ trls, max, progress, description, redDescription, redFields, fields, onClick }) => {
        return (
            <Card
                padding={24}
                borderWidth="default"
                borderRadius={24}
                data-qa="suitable-vacancies-suggest-card"
                stretched
            >
                <Title Element="h3" size="small">
                    {trls[TrlKeys.title]}
                </Title>
                <VSpacing default={24} />
                <ProgressBar max={max} progress={progress} />
                <FieldTags fields={redFields} description={redDescription || ''} onClick={onClick} />
                <FieldTags fields={fields} description={description} onClick={onClick} />
            </Card>
        );
    }
);

export default ResumeCompleteness;
