import { DefaultRootState } from 'react-redux';

import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { ElementaryEducation } from 'src/models/resume/resumeCommon.types';

export const selectResumeEditorOpenedElementaryEducation = selectResumeEditorOpenedType('elementaryEducation');

export const selectResumeEditorElementaryEducation = ({
    resumeEditor,
}: DefaultRootState): ElementaryEducation | undefined => resumeEditor.fields.elementaryEducation;

export const selectResumeEditorElementaryEducationIndex = ({
    resumeEditor,
    applicantResume,
}: DefaultRootState): number => {
    const { index } = resumeEditor.payload as PayloadType<{ index: number }>;
    if (index === undefined) {
        return applicantResume.elementaryEducation.length;
    }
    return index;
};
