import { DefaultRootState } from 'react-redux';

import { selectApplicantResumeArtivacts } from 'src/models/applicant/resume/artifacts/selectors';

import { WITHOUT_PHOTO_ID } from 'src/models/applicant/resume/blocks/position/edit/photo/constants';

export const selectResumeEditorPhoto = ({ resumeEditor }: DefaultRootState): number =>
    resumeEditor.fields.photo || WITHOUT_PHOTO_ID;

export const selectResumeEditorPhotoUrl = (state: DefaultRootState): string | undefined => {
    const photoId = selectResumeEditorPhoto(state);
    if (photoId !== WITHOUT_PHOTO_ID) {
        const photoUrls = selectApplicantResumeArtivacts(state);
        const photoUrl = photoUrls.find(({ id }) => `${id}` === `${photoId}`);
        if (photoUrl) {
            return photoUrl.big;
        }
    }
    return undefined;
};
