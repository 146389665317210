import { CurrencyType } from 'src/models/currencies.types';

export enum Gender {
    Male = 'male',
    Female = 'female',
    Unknown = 'unknown',
}

export interface Salary {
    amount: number | null;
    currency: CurrencyType;
}
