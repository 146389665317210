import { DefaultRootState } from 'react-redux';

import { IndustriesTreeDTO, PopularAreasDTO } from 'src/models/applicant/resume/api/dto';
import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Experience } from 'src/models/resume/resumeCommon.types';

export const selectResumeEditorExperience = ({ resumeEditor }: DefaultRootState): Experience | undefined =>
    resumeEditor.fields.experience;

export const selectResumeEditorOpenedExperience = selectResumeEditorOpenedType('experience');

export const selectResumeEditorExperienceIndex = ({ resumeEditor, applicantResume }: DefaultRootState): number => {
    const { index } = resumeEditor.payload as PayloadType<{ index: number }>;
    if (index === undefined) {
        return applicantResume.experience.length;
    }
    return index;
};

export const selectPopularAreas = ({ resumeEditor }: DefaultRootState): PopularAreasDTO | undefined =>
    resumeEditor.dictionaries.popularAreas;

export const selectIndustriesTree = ({ resumeEditor }: DefaultRootState): IndustriesTreeDTO | undefined =>
    resumeEditor.dictionaries.industriesTree;
