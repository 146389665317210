import { DefaultRootState } from 'react-redux';

import { LoadedFile } from '@hh.ru/magritte-ui-upload';

import { ArtifactDTO } from 'src/models/applicant/resume/api/artifacts/dto';

export const selectApplicantResumeArtivacts = ({ applicantResumeArtifacts }: DefaultRootState): ArtifactDTO[] =>
    applicantResumeArtifacts.artifacts || [];

export const selectArtivactsRemoving = ({ applicantResumeArtifacts }: DefaultRootState): boolean =>
    applicantResumeArtifacts.remove.loading;

export const selectArtivactsLoadedFiles = ({ applicantResumeArtifacts }: DefaultRootState): LoadedFile[] =>
    applicantResumeArtifacts.upload.files;

export const selectArtivactsFilesLoading = ({ applicantResumeArtifacts }: DefaultRootState): boolean =>
    applicantResumeArtifacts.upload.loading;
