import { translation } from '@hh.ru/front-static-app';
import { VSpacing, Text, Button } from '@hh.ru/magritte-ui';

import { Resume } from 'src/models/resume/resume.types';

import styles from './styles.less';

const TrlKeys = {
    fields: {
        title: 'suitableVacancies.suggest.title',
        metro: 'suitableVacancies.suggest.metro',
        photo: 'suitableVacancies.suggest.photo',
        phone: 'suitableVacancies.suggest.phone',
        email: 'suitableVacancies.suggest.email',
        gender: 'suitableVacancies.suggest.gender',
        skills: 'suitableVacancies.suggest.skills',
        salary: 'suitableVacancies.suggest.salary',
        language: 'suitableVacancies.suggest.language',
        lastName: 'suitableVacancies.suggest.lastName',
        proftest: 'suitableVacancies.suggest.proftest',
        birthday: 'suitableVacancies.suggest.birthday',
        keySkills: 'suitableVacancies.suggest.keySkills',
        portfolio: 'suitableVacancies.suggest.portfolio',
        experience: 'suitableVacancies.suggest.experience',
        middleName: 'suitableVacancies.suggest.middleName',
        hasVehicle: 'suitableVacancies.suggest.hasVehicle',
        relocation: 'suitableVacancies.suggest.relocation',
        travelTime: 'suitableVacancies.suggest.travelTime',
        workTicket: 'suitableVacancies.suggest.workTicket',
        employment: 'suitableVacancies.suggest.employment',
        certificate: 'suitableVacancies.suggest.certificate',
        citizenship: 'suitableVacancies.suggest.citizenship',
        videoResume: 'suitableVacancies.suggest.videoResume',
        certificates: 'suitableVacancies.suggest.certificate',
        personalSite: 'suitableVacancies.suggest.personalSite',
        postalAddress: 'suitableVacancies.suggest.postalAddress',
        workSchedule: 'suitableVacancies.suggest.postalAddress',
        recommendation: 'suitableVacancies.suggest.recommendation',
        educationLevel: 'suitableVacancies.suggest.educationLevel',
        specialization: 'suitableVacancies.suggest.specialization',
        relocationArea: 'suitableVacancies.suggest.relocationArea',
        professionalRole: 'suitableVacancies.suggest.professionalRole',
        primaryEducation: 'suitableVacancies.suggest.primaryEducation',
        preferredContact: 'suitableVacancies.suggest.preferredContact',
        driverLicenseTypes: 'suitableVacancies.suggest.driverLicenseTypes',
        additionalEducation: 'suitableVacancies.suggest.additionalEducation',
        attestationEducation: 'suitableVacancies.suggest.attestationEducation',
        businessTripReadiness: 'suitableVacancies.suggest.businessTripReadiness',
    },
};

interface Props {
    fields?: Array<keyof Resume>;
    onClick: (field: keyof Resume) => void;
    description: string;
}

export const FieldTags = translation<Props>(({ trls, description, fields, onClick }) => {
    if (!fields || fields.length === 0) {
        return null;
    }

    return (
        <>
            <VSpacing default={24} />
            <Text typography="label-2-regular" style="secondary">
                {description}
            </Text>
            <VSpacing default={12} />
            <div className={styles.suggestArray}>
                {fields.map((field) => {
                    if (!Object.hasOwn(TrlKeys.fields, field)) {
                        return null;
                    }
                    return (
                        <Button
                            key={String(field)}
                            size="small"
                            style="neutral"
                            mode="secondary"
                            data-qa={`suitable-vacancies-suggest-item-${String(field)}`}
                            onClick={() => onClick(field)}
                        >
                            {trls[TrlKeys.fields[field as keyof typeof TrlKeys.fields]]}
                        </Button>
                    );
                })}
            </div>
        </>
    );
});
