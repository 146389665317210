import { DefaultRootState } from 'react-redux';

import { StringFieldType } from 'src/models/applicant/resume/types/common';
import { PhotoUrl } from 'src/models/resume/resumeCommon.types';

export const selectApplicantPortfolioUrls = ({ applicantResume }: DefaultRootState): PhotoUrl[] =>
    applicantResume.portfolioUrls;

export const selectApplicantPortfolio = ({ applicantResume }: DefaultRootState): StringFieldType<number>[] =>
    applicantResume.portfolio;
