import {
    selectApplicantResumeChangeToHigherEducationLevel,
    selectApplicantResumeChangeToLowerEducationLevel,
    selectApplicantResumeEducationLevel,
} from 'src/models/applicant/resume/blocks/educationLevel/selectors';
import { selectApplicantResumeElementaryEducation } from 'src/models/applicant/resume/blocks/elementaryEducation/selectors';
import { selectApplicantResumePrimaryEducation } from 'src/models/applicant/resume/blocks/primaryEducation/selectors';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

import { selectResumeEditorEducationLevel } from 'src/models/applicant/resume/blocks/educationLevel/editor/selectors';

export const EDUCATION_LEVEL_FIELDS = ['educationLevel'] as const;
type EducationLevelFields = (typeof EDUCATION_LEVEL_FIELDS)[number];

export type EducationLevelBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, EducationLevelFields>>;

export const EducationLevelConfig: EducationLevelBlockConfig = {
    init: (store) => {
        return {
            educationLevel: selectApplicantResumeEducationLevel(store),
        };
    },

    changed: (store) => {
        const editorEducationLevel = selectResumeEditorEducationLevel(store);
        const resumeEducationLevel = selectApplicantResumeEducationLevel(store);
        return editorEducationLevel !== resumeEducationLevel;
    },

    validate: () => {
        return {};
    },

    prepare: ({ educationLevel }, store) => {
        const resumePrimaryEducation = selectApplicantResumePrimaryEducation(store);
        const resumeElementaryEducation = selectApplicantResumeElementaryEducation(store);
        const isChangeToLower = selectApplicantResumeChangeToLowerEducationLevel(store);
        const isChangeToHigher = selectApplicantResumeChangeToHigherEducationLevel(store);
        if (isChangeToHigher) {
            return {
                educationLevel: [{ string: educationLevel }],
                primaryEducation: resumeElementaryEducation,
                elementaryEducation: [],
            };
        }
        if (isChangeToLower) {
            return {
                educationLevel: [{ string: educationLevel }],
                primaryEducation: [],
                elementaryEducation: resumePrimaryEducation,
            };
        }
        return {
            educationLevel: [{ string: educationLevel }],
        };
    },
};
