import { MonthIndex } from 'src/models/monthIndex';
import { KeySkill } from 'src/models/resume/resumeCommon.types';
import {
    VerifiedSkill,
    VSkillValidityStatus,
    VerifiedSkillCategory,
} from 'src/models/resume/resumeVerifiedSkills.types';
import { SkillsLevelsKeys } from 'src/models/skills';
import { SKILLS_LEVELS_BY_RANK, SKILLS_LEVELS_ORDER } from 'src/utils/constants/skills';

import { ResumeKeySkill } from 'src/models/applicant/resume/blocks/keySkills/types';

type SkillByLevelType = Partial<Record<SkillsLevelsKeys, ResumeKeySkill[]>>;
export const VERIFIED_KEY_SKILLS_STATES = [VSkillValidityStatus.Effective, VSkillValidityStatus.Grace];

const DOT_TAG: ResumeKeySkill = {
    id: -1,
    name: '...',
};
const MAX_SKILL_MOBILE_COUNT = 5;
export const makeSkillsByLevel = (
    verifiedSkills: VerifiedSkill[] = [],
    advancedKeySkills: KeySkill[],
    isMobile: boolean
) => {
    return advancedKeySkills.reduce<SkillByLevelType>((skillsByLevel, resumeKeySkill) => {
        const verifiedSkill = verifiedSkills.find(
            (verified) => verified.id === resumeKeySkill.id && verified.category === VerifiedSkillCategory.Skill
        );
        const rang = verifiedSkill?.level?.rank || 0;
        const skillLevel = SKILLS_LEVELS_BY_RANK[rang];
        const skillCount = skillsByLevel[skillLevel]?.length || 0;

        if (skillCount > MAX_SKILL_MOBILE_COUNT && isMobile) {
            return skillsByLevel;
        }

        const skill: ResumeKeySkill = {
            ...resumeKeySkill,
            verifiedInfo: {
                type: getVerifiedType(verifiedSkill),
                date: getSkillTestDate(verifiedSkill),
                verified: isSkillVerified(verifiedSkill),
                fullVerified: isSkillFullVerified(verifiedSkill),
            },
        };

        if (skillsByLevel[skillLevel]) {
            if (skillCount === MAX_SKILL_MOBILE_COUNT && isMobile) {
                skillsByLevel[skillLevel].push(DOT_TAG);
            } else {
                skillsByLevel[skillLevel].push(skill);
            }
        } else {
            skillsByLevel[skillLevel] = [skill];
        }
        return skillsByLevel;
    }, {});
};

export function hasTitles(levels: SkillsLevelsKeys[]) {
    if (levels.length !== 1) {
        return true;
    }
    return levels[0] !== SkillsLevelsKeys.NONE;
}

export const makeSkillsLevels = (skillsByLevel: SkillByLevelType) =>
    Object.keys(skillsByLevel).sort((a, b) => SKILLS_LEVELS_ORDER.indexOf(a) - SKILLS_LEVELS_ORDER.indexOf(b));

export function isSkillFullVerified(verifiedSkill?: VerifiedSkill) {
    /**
     * Подтверждена и теория, и практика
     */
    if (!verifiedSkill) {
        return false;
    }
    return (
        VERIFIED_KEY_SKILLS_STATES.includes(verifiedSkill?.theory?.validity.state) &&
        VERIFIED_KEY_SKILLS_STATES.includes(verifiedSkill?.practice?.validity.state)
    );
}

export function isSkillVerified(verifiedSkill?: VerifiedSkill) {
    /**
     * Подтверждена либо теория, либо практика
     */
    if (!verifiedSkill) {
        return false;
    }
    return (
        VERIFIED_KEY_SKILLS_STATES.includes(verifiedSkill?.theory?.validity.state) ||
        VERIFIED_KEY_SKILLS_STATES.includes(verifiedSkill?.practice?.validity.state)
    );
}

export function getVerifiedType(verifiedSkill?: VerifiedSkill): 'theory' | 'practice' | 'full' | 'none' {
    if (!verifiedSkill) {
        return 'none';
    }

    if (verifiedSkill.theory != null && verifiedSkill.practice != null) {
        return 'full';
    }

    if (verifiedSkill.theory != null) {
        return 'theory';
    }

    return 'practice';
}

export function getSkillTestDate(verifiedSkill?: VerifiedSkill) {
    if (!verifiedSkill) {
        return { year: '', month: 1 as MonthIndex };
    }
    const attemptedAt = verifiedSkill?.theory?.attemptedAt || verifiedSkill?.practice?.attemptedAt;

    const date = attemptedAt ? new Date(attemptedAt) : '';
    const year = date ? date.getFullYear().toString() : '';
    const month = date ? (date?.getMonth() as MonthIndex) : 0;

    return { year, month };
}
