import { DefaultRootState } from 'react-redux';

import { selectApplicantResumeElementaryEducation } from 'src/models/applicant/resume/blocks/elementaryEducation/selectors';
import { NEW_ID } from 'src/models/applicant/resume/editor/lib';
import { selectResumeEditorPayload } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { AdditionalEducation, ElementaryEducation } from 'src/models/resume/resumeCommon.types';

export const getNewElementaryEducation = (): AdditionalEducation => ({
    id: NEW_ID,
    name: '',
    year: 0,
    result: '',
    organization: '',
});

export const isNewElementaryEducationChanged = ({ name, year }: ElementaryEducation): boolean =>
    name !== '' || year !== 0;

export const getSelectedElementaryEducationByIndex = (store: DefaultRootState): ElementaryEducation => {
    const { index } = selectResumeEditorPayload(store) as PayloadType<{ index: number }>;
    const elementaryEducation = selectApplicantResumeElementaryEducation(store);

    const education = elementaryEducation[index];
    if (education) {
        return {
            id: education.id,
            name: education.name === null ? '' : education.name,
            year: education.year === null ? 0 : education.year,
        };
    }
    return elementaryEducation[index] || getNewElementaryEducation();
};
