import { DefaultRootState } from 'react-redux';

import { StringFieldType } from 'src/models/applicant/resume/types/common';
import { KeySkill } from 'src/models/resume/resumeCommon.types';
import { VerifiedSkill } from 'src/models/resume/resumeVerifiedSkills.types';

export const selectApplicantResumeKeySkills = ({ applicantResume }: DefaultRootState): StringFieldType[] =>
    // Скилы в резюме
    applicantResume.keySkills || [];

export const selectApplicantResumeAdvancedKeySkills = ({ applicantResume }: DefaultRootState): KeySkill[] =>
    // Скилы в резюме, с доп инфой
    applicantResume.advancedKeySkills || [];

export const selectApplicantSkills = ({ applicantResume }: DefaultRootState): VerifiedSkill[] =>
    // Все скилы соискателя
    applicantResume.resumeApplicantSkills || [];

export const selectApplicantVerifiedSkills = ({ applicantResume }: DefaultRootState): VerifiedSkill[] =>
    // Подтвержденные скилы соискателя
    applicantResume.verifiedSkills || [];
