import { DefaultRootState } from 'react-redux';

import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Certificate } from 'src/models/resume/resumeCommon.types';

export const selectResumeEditorOpenedCertificate = selectResumeEditorOpenedType('certificate');

export const selectResumeEditorCertificate = ({ resumeEditor }: DefaultRootState): Certificate | undefined =>
    resumeEditor.fields.certificate;

export const selectResumeEditorCertificateIndex = ({ resumeEditor, applicantResume }: DefaultRootState): number => {
    const { index } = resumeEditor.payload as PayloadType<{ index: number }>;
    if (index === undefined) {
        return applicantResume.certificate.length;
    }
    return index;
};
