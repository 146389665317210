export enum VerifiedBy {
    None = 'NONE',
    Theory = 'THEORY',
    Practice = 'PRACTICE',
    TheoryAndPractice = 'THEORY_AND_PRACTICE',
}

export enum VerifiedSkillCategory {
    Skill = 'SKILL',
    Lang = 'LANG',
}

export enum VSkillValidityStatus {
    Effective = 'EFFECTIVE',
    None = 'NONE',
    Grace = 'GRACE',
    Expired = 'EXPIRED',
}

export enum VSkillValidityValidUntil {
    ValidUntil = 'valid_until',
}

export enum VerifiedSkillPlatform {
    KaDelaQuiz = 'KAK_DELA_QUIZ',
}

export enum VerifiedSkillResultStatus {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
}

export enum VerifiedSkillResultType {
    Score = 'SCORE',
}

export interface VerifiedSkillLevel {
    id: number;
    // TODO: апи возвращает internal_id
    internalId: string;
    name: string;
    rank: 0 | 1 | 2 | 3;
}

export interface VerifiedSkillMethodIconItem {
    original: string;
}

export interface VerifiedSkillMethodIcon {
    dark: VerifiedSkillMethodIconItem;
    defaults: VerifiedSkillMethodIconItem;
    light: VerifiedSkillMethodIconItem;
}

export interface VerifiedSkillMethodSource {
    description: string | null;
    headline: VerifiedSkillMethodIcon;
    icon: VerifiedSkillMethodIcon;
    id: number;
    name: string;
}

export interface VerifiedSkillMethodBrandingSettings {
    ageLabel: string;
    ctaButtonEnabled: boolean;
    enabled: boolean;
}

export interface VerifiedSkillMethod {
    description: string;
    icon: VerifiedSkillMethodIcon;
    id: number;
    name: string;
    platform: VerifiedSkillPlatform;
    source?: VerifiedSkillMethodSource;
    brandingSettings: VerifiedSkillMethodBrandingSettings;
}

export interface VerifiedSkillResultScore {
    actual: number;
    max: number;
}

export interface VerifiedSkillResult {
    score: VerifiedSkillResultScore;
    status: VerifiedSkillResultStatus;
    type: VerifiedSkillResultType;
}

interface SkillValidity {
    state: VSkillValidityStatus;
    [VSkillValidityValidUntil.ValidUntil]: string | null;
}
export interface VerifiedSkillVerification {
    attemptedAt: string;
    id: number;
    level: VerifiedSkillLevel | null;
    method: VerifiedSkillMethod;
    result: VerifiedSkillResult;
    validity: SkillValidity;
}

export interface VerifiedSkill {
    category: VerifiedSkillCategory;
    id: number;
    level: VerifiedSkillLevel | null;
    name: string;
    validity: SkillValidity;
    verifications: Array<VerifiedSkillVerification>;
    verifiedBy: VerifiedBy;
    theory: VerifiedSkillVerification | null;
    practice: VerifiedSkillVerification | null;
}

export type VerifiedSkills = Array<VerifiedSkill>;
export interface VerifiedSkillsWithReportsFlag {
    skills: VerifiedSkills;
    hasReports: boolean;
}

export interface VerifiedSkillReportQuestion {
    question: string;
    answers: string[];
}
export interface VerifiedSkillReportTopic {
    name: string;
    description: string;
    score: VerifiedSkillResultScore | null;
    questions: VerifiedSkillReportQuestion[] | null;
}
