/* eslint-disable camelcase */
import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { ResumeApi } from 'src/models/applicant/resume/api';
import { isSuccess } from 'src/models/applicant/resume/api/lib';
import { putKeySkillsLevelsUpdate } from 'src/models/applicant/resume/api/skills';
import { UpdateUserSkillLevelDTO } from 'src/models/applicant/resume/api/skills/dto';
import {
    selectApplicantSkills,
    selectApplicantResumeKeySkills,
} from 'src/models/applicant/resume/blocks/keySkills/selectors';
import { toggleResumeEditorLoading, showNotification } from 'src/models/applicant/resume/editor/store/slice';
import { Thunk } from 'src/models/applicant/resume/editor/types/actions';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import { keys } from 'src/models/applicant/resume/lib/common/object';
import { selectResumeHash } from 'src/models/applicant/resume/selectors';
import { setApplicantResumeFields } from 'src/models/applicant/resume/slice';

import {
    makeLevelsMap,
    skillSortWeight,
    getChangedLevels,
    skillLevelViewToLevelId,
} from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/lib';
import {
    selectLevelsBySkillId,
    selectOriginalLevelsBySkillId,
} from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/selector';
import {
    setSkillList,
    setSkillLevels,
    setSkillOriginalLevels,
    toggleSkillsLevelsAlert,
    resetSkillsLevelsEditor,
    toggleSkillsLevelsEditor,
} from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/slice';

export const openSkillsLevelsEditor = () => {
    return (dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const applicantSkills = selectApplicantSkills(getState());
        const resumeKeySkills = selectApplicantResumeKeySkills(getState());

        const resumeSkillsNames = resumeKeySkills.map(({ string }) => string);
        const applicantKeySkills = applicantSkills.filter(
            (verifiedSkill) => verifiedSkill.category === 'SKILL' && resumeSkillsNames.includes(verifiedSkill.name)
        );
        const sortedSkills = applicantKeySkills.sort((a, b) => skillSortWeight(a) - skillSortWeight(b));

        const levels = makeLevelsMap(sortedSkills);

        dispatch(setSkillLevels(levels));
        dispatch(setSkillOriginalLevels(levels));

        dispatch(setSkillList(sortedSkills));
        dispatch(toggleSkillsLevelsEditor(true));
    };
};

export const closeSkillsLevelsEditor = () => {
    return (dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const levels = selectLevelsBySkillId(getState());
        const originalLevels = selectOriginalLevelsBySkillId(getState());

        const changedLevels = getChangedLevels(originalLevels, levels);

        if (!isEmpty(changedLevels)) {
            dispatch(toggleSkillsLevelsAlert(true));
            return;
        }

        dispatch(resetSkillsLevelsEditor());
    };
};

export const cancelSkillsLevelsEditing = () => {
    return (dispatch: Dispatch): void => {
        dispatch(resetSkillsLevelsEditor());
    };
};

export const updateKeySkillsLevels = (): Thunk => {
    return async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const levels = selectLevelsBySkillId(getState());
        const originalLevels = selectOriginalLevelsBySkillId(getState());

        const changedLevels = getChangedLevels(originalLevels, levels);

        if (isEmpty(changedLevels)) {
            dispatch(resetSkillsLevelsEditor());
            return;
        }

        dispatch(toggleResumeEditorLoading({ type: 'saving', loading: true }));

        try {
            const resumeHash = selectResumeHash(getState());
            const body = keys(changedLevels)
                .map((skillId) => {
                    const rank = levels[skillId];
                    return {
                        category: 'SKILL',
                        level_id: skillLevelViewToLevelId(rank, 'SKILL'),
                        skill_id: parseInt(skillId, 10),
                    } as UpdateUserSkillLevelDTO;
                })
                .filter(({ level_id }) => level_id !== 0);

            await putKeySkillsLevelsUpdate(body);

            const response = await ResumeApi.get(resumeHash);

            if (isSuccess(response)) {
                dispatch(setApplicantResumeFields(response.resume));
                dispatch(resetSkillsLevelsEditor());
                dispatch(showNotification('success-save'));
                return;
            }

            dispatch(showNotification('error-api'));
        } catch (_) {
            dispatch(showNotification('error-api'));
        } finally {
            dispatch(toggleResumeEditorLoading({ type: 'saving', loading: false }));
        }
    };
};
