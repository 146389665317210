import { DefaultRootState } from 'react-redux';

import { ModelData } from '@hh.ru/magritte-ui';

import { selectResumeExperience } from 'src/models/applicant/resume/blocks/experience/selectors';
import { NEW_ID } from 'src/models/applicant/resume/editor/lib';
import { selectResumeEditorPayload } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Experience } from 'src/models/resume/resumeCommon.types';

export const getNewExperience = (): Experience => ({
    id: NEW_ID,
    position: '',
    description: '',
    startDate: '',
    endDate: '',
    companyName: '',
    companyUrl: '',
    companyIndustries: [],
    companyLogos: null,
    companyIndustryId: null,
    companyAreaId: null,
    companyId: null,
    industries: [],
    employerId: null,
    companyState: null,
    companyAreaTitle: '',
});

export const getSelectedExperienceByIndex = (store: DefaultRootState): Experience => {
    const { index } = selectResumeEditorPayload(store) as PayloadType<{ index: number }>;

    const experiences = selectResumeExperience(store);

    const experience = experiences[index];

    if (experience) {
        return {
            ...experience,
            endDate: experience.endDate === null ? '' : experience.endDate,
        };
    }

    return experiences[index] || getNewExperience();
};

export const isNewExperienceChanged = ({
    position,
    description,
    startDate,
    endDate,
    companyName,
    companyUrl,
    companyAreaId,
    companyIndustries,
}: Experience): boolean =>
    position !== '' ||
    description !== '' ||
    startDate !== '' ||
    endDate !== '' ||
    companyUrl !== '' ||
    companyName !== '' ||
    companyAreaId !== null ||
    companyIndustries.length > 0;

export const transformExperienceToSave = (experience: Experience[]) => {
    return experience.map(({ companyLogos, interval, companyAreaTitle, ...fields }) => ({
        ...fields,
    })) as Experience[];
};

export const findIndustryFromTreeModelData = (treeItems: ModelData[], industryId: string): ModelData | undefined => {
    for (const item of treeItems) {
        if (item.id === industryId) {
            return item;
        }

        if (item.items) {
            const foundIndustry = findIndustryFromTreeModelData(item.items, industryId);

            if (foundIndustry) {
                return foundIndustry;
            }
        }
    }

    return undefined;
};
