import { Salary } from 'src/models/applicant/resume/blocks/position/types';

export const isSalaryEmpty = (salary?: Salary) => {
    if (!salary) {
        return true;
    }
    const { amount } = salary;

    if (!amount) {
        return true;
    }

    if (amount === 0) {
        return true;
    }

    return false;
};
