import { selectApplicantResumeEducationLevel } from 'src/models/applicant/resume/blocks/educationLevel/selectors';
import { selectApplicantResumeElementaryEducation } from 'src/models/applicant/resume/blocks/elementaryEducation/selectors';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate';
import { isChangedByKeys, isNewArrayField, updateItemById } from 'src/models/applicant/resume/editor/lib';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig, PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { ElementaryEducation } from 'src/models/resume/resumeCommon.types';

import {
    getSelectedElementaryEducationByIndex,
    isNewElementaryEducationChanged,
} from 'src/models/applicant/resume/blocks/elementaryEducation/editor/lib';
import { selectResumeEditorElementaryEducation } from 'src/models/applicant/resume/blocks/elementaryEducation/editor/selectors';

export const ELEMENTARY_EDUCATION_FIELD = 'elementaryEducation';
type ElementaryEducationFields = typeof ELEMENTARY_EDUCATION_FIELD;

export type ElementaryEducationBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, ElementaryEducationFields>>;

const isElementaryEducationChanges = isChangedByKeys<ElementaryEducation>(['id', 'name', 'year']);

export const elementaryEducationConfig: ElementaryEducationBlockConfig = {
    init: (store) => {
        return {
            elementaryEducation: getSelectedElementaryEducationByIndex(store),
        };
    },

    changed: (store) => {
        const editorElementaryEducation = selectResumeEditorElementaryEducation(store);
        const resumeElementaryEducations = selectApplicantResumeElementaryEducation(store);

        if (!editorElementaryEducation) {
            return false;
        }

        if (isNewArrayField(editorElementaryEducation)) {
            return isNewElementaryEducationChanged(editorElementaryEducation);
        }

        const resumeElementaryEducation = resumeElementaryEducations.find(
            ({ id }) => id === editorElementaryEducation.id
        );
        if (!resumeElementaryEducation) {
            return false;
        }

        return isElementaryEducationChanges(resumeElementaryEducation, editorElementaryEducation);
    },

    prepare: ({ elementaryEducation }, store) => {
        const resumeEducationLevel = selectApplicantResumeEducationLevel(store);
        const resumeElementaryEducation = selectApplicantResumeElementaryEducation(store);
        const educationLevel = [{ string: resumeEducationLevel === '' ? 'secondary' : resumeEducationLevel }];

        if (isNewArrayField(elementaryEducation)) {
            return {
                educationLevel,
                elementaryEducation: [
                    ...resumeElementaryEducation,
                    { ...elementaryEducation, id: null } as unknown as ElementaryEducation,
                ],
            };
        }

        return {
            educationLevel,
            elementaryEducation: updateItemById(resumeElementaryEducation, elementaryEducation),
        };
    },

    validate: ({ elementaryEducation }, conditions) => ({
        ...validateEditorFieldByKey('elementaryEducation', elementaryEducation, conditions),
    }),

    delete: (store, payload) => {
        const elementaryEducation = selectApplicantResumeElementaryEducation(store);
        const { index } = payload as PayloadType<{ index: number }>;
        return { elementaryEducation: elementaryEducation.filter((_, i) => i !== index) };
    },
};
