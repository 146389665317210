import { DefaultRootState } from 'react-redux';

import { selectApplicantResumeElementaryEducation } from 'src/models/applicant/resume/blocks/elementaryEducation/selectors';
import { selectApplicantResumePrimaryEducation } from 'src/models/applicant/resume/blocks/primaryEducation/selectors';

import { selectResumeEditorEducationLevel } from 'src/models/applicant/resume/blocks/educationLevel/editor/selectors';

export const selectApplicantResumeEducationLevel = ({ applicantResume }: DefaultRootState): string =>
    applicantResume.educationLevel?.[0]?.string ?? '';

export const selectApplicantResumeChangeToLowerEducationLevel = (state: DefaultRootState): boolean => {
    const editorLevel = selectResumeEditorEducationLevel(state);
    const resumeLevel = selectApplicantResumeEducationLevel(state);
    const primaryEducation = selectApplicantResumePrimaryEducation(state);

    return editorLevel === 'secondary' && resumeLevel !== 'secondary' && !!primaryEducation.length;
};

export const selectApplicantResumeChangeToHigherEducationLevel = (state: DefaultRootState): boolean => {
    const editorLevel = selectResumeEditorEducationLevel(state);
    const resumeLevel = selectApplicantResumeEducationLevel(state);
    const elementaryEducation = selectApplicantResumeElementaryEducation(state);

    return editorLevel !== 'secondary' && resumeLevel === 'secondary' && !!elementaryEducation.length;
};

export const selectEducationLevels = (state: DefaultRootState) => state.educationLevels;
